import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)
const routes = [{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/Home',
		name: 'home',
		component: Home
	},
	{
		path: '/demo',
		name: 'demo',
		component: () => import('../views/demo.vue')
	},
	{
		path: '/AboutUs',
		name: 'About',
		component: () => import('../views/About.vue')
	},
	{
		path: '/Competencies',
		name: 'Serve',
		component: () => import('../views/Serve.vue')
	},
	{
		path: '/industry',
		name: 'industry',
		component: () => import('../views/industry.vue')
	},
	{
		path: '/knowledge',
		name: 'knowledge',
		component: () => import('../views/knowledge.vue')
	},
	{
		path: '/responsibility',
		name: 'responsibility',
		component: () => import('../views/responsibility.vue')
	},
	{
		path: '/ContactUs',
		name: 'ContactUs',
		component: () => import('../views/ContactUs.vue')
	},
	{
		path: '/Products',
		name: 'Products',
		component: () => import('../views/Products.vue')
	},
	{
		path: '/product',
		name: 'product',
		component: () => import('../views/product.vue')
	},
	{
		path: '/ProductDetail',
		name: 'ProductDetail',
		component: () => import('../views/ProductsDetail.vue')
	},
	{
		path: '/enterid',
		name: 'enterid',
		component: () => import('../views/enterid.vue')
	},
]

const router = new VueRouter({
	mode: 'history',
	routes:routes
})

export default router
