// ga.js
export default {
    install(Vue, options) {
      if (!options.id) {
        throw new Error('Google Analytics ID is required');
      }
      let dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', options.id);
    }
  }